import { useContext, useState, useCallback } from 'react';
import AuthContext from '../contexts/auth-context';

const useHttp = () => {
  const authCtx = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  let apiUrl = 'https://api-prod.plab.so';

  const sendRequest = useCallback(
    async (requestConfig, returnResObj, loader = true) => {
      if (loader) setIsLoading(true);

      const response = await fetch(`${apiUrl}/${requestConfig.urlPath}`, {
        method: requestConfig.method ? requestConfig.method : 'GET',
        headers: requestConfig.headers
          ? requestConfig.headers
          : {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authCtx.token}`,
            },
        body: requestConfig.body ? JSON.stringify(requestConfig.body) : null,
      });

      returnResObj(response);
      if (loader) setIsLoading(false);
    },
    [apiUrl, authCtx.token]
  );

  return {
    isLoading,
    sendRequest,
  };
};

export default useHttp;
